import {Layout} from "../../components/Layout"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import og from "../../data/images/patreon/OpenGraph.png"
import {Page} from "../../components/PrivateRoutePage"
import {Button, Card, CardContent, Grid} from "@material-ui/core"
import {ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../components/Shared"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {TFunction} from "react-i18next"
import {PatreonTitleGrid} from "./Shared"
import {ProfilePage} from "./patron/ProfilePage"
import YouTube from "react-youtube"
import {TextCenter} from "../diy/components/Shared"
import {HomePage} from "../../pages"
import {useBreadcrumb} from "../../hooks/useBreadcrumb"
import {usePatreon} from "./patron/usePatreon"
import {useEffect, useState} from "react"
import styled from "@emotion/styled"
import {StaticImage} from "gatsby-plugin-image"

import takeControl from "../../data/images/patreon/PRENEZ LE CONTROLE DE RECALBOX.jpg"
import takeControlEn from "../../data/images/patreon/PRENEZ LE CONTROLE DE RECALBOX ENG.jpg"


import scrapImg from "../../data/images/patreon/Scrapez moins, jouez plus.jpg"
import scrapImgEn from "../../data/images/patreon/Scrapez moins, jouez plus ENG.jpg"

import casseteImg from "../../data/images/patreon/Cassette audio Patreon 2.jpg"
import casseteImgEn from "../../data/images/patreon/Cassette audio Patreon 2 ENG.jpg"

const FullWidthImage = styled.img({
  width: "100%"
})

const PatreonCtaCard = (props: { t: TFunction<string>, title: string, subtitle?: string, content: string, alignContent?: "center" | "left", buttonVariant: "text" | "outlined" | "contained", buttonText: string, buttonHref: string }) => {
  const {t, title, subtitle, content, alignContent, buttonVariant, buttonText, buttonHref} = props
  const button = buttonHref.startsWith("http")
    ? <Button href={buttonHref} target={"_blank"} css={{marginTop: "2em"}} variant={buttonVariant}
              color={"secondary"}>{t(buttonText)}</Button>
    : <Button component={Link} to={buttonHref} css={{marginTop: "2em"}} variant={buttonVariant}
              color={"secondary"}>{t(buttonText)}</Button>

  return <Card>
    <CardContent css={{textAlign: "center"}}>

      <TransMdTypo variant={"h3"}>{title}</TransMdTypo>
      {subtitle &&
      <TransMdTypo gutterBottom variant={"h6"}>{subtitle}</TransMdTypo>
      }
      <TransMdTypo css={{textAlign: alignContent ?? "center"}}>{content}</TransMdTypo>
      {button}
    </CardContent>
  </Card>
}
const Patreon = () => {
  const {t, i18n} = useTranslation()
  const breadCrumb = useBreadcrumb(PatreonPage)
  const patreon = usePatreon()
  const [state, setState] = useState({patron: false})

  useEffect(() => {
    patreon.isPatron().then(patron => setState({...state, patron}))
  }, [])
  return <Layout title={t("patreon^Recalbox + Patreon")} imageUrl={og} description={t("patreon^Recalbox needs you! Support the project on Patreon and enjoy premium features!")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <Grid container justify={"center"} spacing={3}>

          <Grid item xs={12} css={{marginBottom: "2em"}}>
            <TextCenter>
              <YouTube videoId={"zNKNNg1WyAo"} css={{width: "80%", height: "450px", borderRadius: "1em"}}/>
            </TextCenter>
          </Grid>
          <PatreonTitleGrid/>

          <Grid item xs={12} md={6}>
            {state.patron ?
              <PatreonCtaCard t={t} title={"patreon^Thank you for your support ❤️"}
                              content={"patreon^Patron Features"}
                              alignContent={"left"}
                              buttonText={"patreon^Visit Patreon"}
                              buttonHref={process.env.GATSBY_PATREON_PAGE || "#"}
                              buttonVariant={"contained"}/>
              :
              <PatreonCtaCard t={t} title={"patreon^Become the patron of Recalbox..."}
                              subtitle={"patreon^...and enjoy all these advantages!"}
                              content={"patreon^Patron Features"}
                              alignContent={"left"}
                              buttonText={"patreon^Become a patron"}
                              buttonHref={process.env.GATSBY_PATREON_PAGE || "#"}
                              buttonVariant={"contained"}/>
            }

          </Grid>
          <Grid item xs={12} md={6}>
            {state.patron ?
              <PatreonCtaCard t={t} title={"patreon^Access your Patron area"}
                              content={"patreon^on your patron area"}
                              alignContent={"left"}
                              buttonText={"patreon^Patron area"}
                              buttonHref={ProfilePage.getUrl()}
                              buttonVariant={"outlined"}/>
              :
              <PatreonCtaCard t={t} title={"patreon^Already supporting Recalbox on Patreon?"}
                              content={"patreon^on your patron area"}
                              alignContent={"left"}
                              buttonText={"patreon^Log in to the Patron's area"}
                              buttonHref={ProfilePage.getUrl()}
                              buttonVariant={"outlined"}/>
            }
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <PatreonSection>

                  <TransMdTypo variant={"h2"}>patreon^Recalbox is looking for... **their new patrons** !</TransMdTypo>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} css={{height: "100%"}}>
                      <FullWidthImage alt={""} src={i18n.language === "fr" ? takeControl : takeControlEn}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TransMdTypo>patreon^part1-1</TransMdTypo>
                      <TransMdTypo>patreon^part1-2</TransMdTypo>
                    </Grid>
                  </Grid>
                  <TransMdTypo>patreon^part1-3</TransMdTypo>

                  <TransMdTypo variant={"h3"}>patreon^Patron exclusivity #1 : **AUTO SCRAP** !</TransMdTypo>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} css={{height: "100%"}}>
                      <FullWidthImage alt={""} src={i18n.language === "fr" ? scrapImg : scrapImgEn}/>
                    </Grid>
                    <Grid item xs={12} lg={6} css={{height: "100%"}}>
                      <TransMdTypo>patreon^part2-1
                      </TransMdTypo>
                    </Grid>
                  </Grid>


                  <TransMdTypo variant={"h3"}>patreon^Patron exclusivity #2 : **PREMIUM RETROGAMING PLAYLIST**!</TransMdTypo>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} css={{height: "100%"}}>
                      <FullWidthImage alt={""} src={i18n.language === "fr" ? casseteImg : casseteImgEn}/>
                    </Grid>
                    <Grid item xs={12} lg={6} css={{height: "100%"}}>
                      <TransMdTypo>patreon^part3-1</TransMdTypo>
                    </Grid>
                  </Grid>

                  <TransMdTypo variant={"h3"}>patreon^Patron exclusivity #3 : **VOTE FOR TOMORROW'S RECALBOX**!</TransMdTypo>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} css={{height: "100%"}}>
                      <StaticImage alt={""} src={"../../data/images/patreon/vote.jpg"}/>
                    </Grid>
                    <Grid item xs={12} lg={6} css={{height: "100%"}}>
                      <TransMdTypo>patreon^part4-1</TransMdTypo>
                    </Grid>
                  </Grid>

                  <TransMdTypo variant={"h3"}>patreon^Patron exclusivity #4 : **EXCLUSIVE PROMOTIONS!**</TransMdTypo>
                  <StaticImage alt={""} src={"../../data/images/patreon/Partenaires.png"}/>
                  <TransMdTypo>patreon^part5-1</TransMdTypo>

                  <TransMdTypo variant={"h3"}>patreon^RECALBOX: FREE FOR ALL. **FOREVER.**</TransMdTypo>
                  <TransMdTypo>patreon^part6-1</TransMdTypo>
                </PatreonSection>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </StarsImageContainer>

  </Layout>
}
const PatreonSection = styled.div(({theme}) => ({
  p: {marginBottom: "1em"},
  h3: {marginTop: "1.5em"},
  "h2,h3": {strong: {color: theme.customPalette.patreon.main}}
}))

const getUrl = () => "/patreon/"
export const PatreonPage: Page = {
  Page: Patreon,
  getUrl,
  parentPage: () => HomePage,
  breadCrumb: {link: getUrl(), name: "Patreon"}
}
